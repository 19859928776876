import { FC } from 'react';
import { ContactInfo, ContactInfoProps } from '../contact-info';
import { Container, StyledDescription } from './error-card.styled';

export type Props = ContactInfoProps & {
    description: string;
};

export const ErrorCard: FC<Props> = ({ description, contactInfo }) => {
    return (
        <Container>
            <StyledDescription>
                <h3>Beskrivelse:</h3>
                <p>{description}</p>
            </StyledDescription>

            <ContactInfo contactInfo={contactInfo} />
        </Container>
    );
};
