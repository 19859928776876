import { device } from '../../../../lib/media-query';
import styled from 'styled-components';

export const Overlay = styled.div<{ orientation: string }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: hsl(0deg 0% 0% / 0.5);
    display: flex;
    justify-content: ${(props) => (props.orientation === 'aside' ? 'flex-end' : 'center')};
    align-items: flex-start;
    isolation: isolate;
    z-index: 101;
`;

export const Wrapper = styled.div<{ orientation: string }>`
    box-shadow: ${({ theme }) => theme.shadows.small};
    background-color: ${({ theme }) => theme.palette.background.default};
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.palette.common.black};
    width: 100%;
    height: 100%;
    min-height: 100%;

    @media ${device.tablet} {
        position: relative;
        width: 650px;
        height: ${(props) => (props.orientation === 'aside' ? '100%' : 'auto')};
        ${(props) => (props.orientation !== 'aside' ? 'max-height: 600px;' : '')}
        top: ${(props) => (props.orientation === 'aside' ? '0' : '10%')};
        min-height: revert;
        padding-bottom: 20px;
        ${(props) => (props.orientation === 'aside' ? 'padding: 0 30px;' : '')}
    }

    @media (max-height: 700px) {
        top: 0;
        height: 100%;
        min-height: 100%;
    }
`;

export const ModalTopSection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 30px;
    padding-top: 30px;
    padding-bottom: 15px;

    @media ${device.tablet} {
        padding-bottom: 0;
    }
`;

export const ModalDivider = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.medium};
    margin: 1px 0px;

    @media ${device.tablet} {
        margin: 15px 0px;
        margin-bottom: 0;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const HeadersWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.h2`
    font-family: 'Corporate E';
`;

export const SubHeader = styled.p<{ fontWeight?: string }>`
    color: ${({ theme }) => theme.palette.text.hint};
    font-size: 16px;
    ${(props) => props.fontWeight && `font-weight: ${props.fontWeight}`};
`;

export const ChildrenWrapper = styled.div<{ removeOverflow: boolean }>`
    align-items: center;

    overflow-y: ${(props) => (props.removeOverflow ? 'unset' : 'auto')};
    scrollbar-width: thin;

    margin-bottom: 10px;

    @media ${device.tablet} {
        height: auto;
    }
`;
