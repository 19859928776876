import styled from 'styled-components';
import { mixins } from '../../../themes';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    padding: 30px;
`;

export const StyledDescription = styled.div`
    display: flex;
    flex-direction: column;
    ${mixins.gap(0.5)};
    align-items: baseline;
    margin-bottom: 10px;
`;
