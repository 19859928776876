import { FC } from 'react';
import { IShiftBy } from './shift-by.props';

export const ShiftBy: FC<IShiftBy> = ({ x = 0, y = 0, children }) => {
    return (
        <div
            style={{
                display: 'inline-block',
                transform: `translate(${x}px, ${y}px)`,
            }}
        >
            {children}
        </div>
    );
};
