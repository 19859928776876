import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const Wrapper = styled.section<{ orientation: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    @media ${device.tablet} {
        ${(props) => (props.orientation === 'center' ? 'max-height: 600px;' : '')}
        padding-bottom: 0px;
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 20px;
    width: 100%;
    margin-top: auto;

    & > button:not(:last-child) {
        margin-right: 10px;
    }
`;
