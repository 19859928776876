import { FC } from 'react';
import { ISimpleModal } from './simple-modal.props';
import { ModalWrapper } from '../wrapper';
import { Wrapper, Footer } from './simple-modal.styled';
import { Button } from '../../../../components/shared/button';

export const SimpleModal: FC<ISimpleModal> = ({
    isVisible = false,
    headerText = '',
    subHeaderText = '',
    subHeaderFontWeight,
    closeText = 'Luk',
    orientation = 'center',
    closeAction,
    children,
    confirmAction = closeAction,
    hideCta = false,
    ctaVariant = 'primary',
}) => {
    return (
        <ModalWrapper
            cancelAction={closeAction}
            isVisible={isVisible}
            headerText={headerText}
            orientation={orientation}
            subHeaderText={subHeaderText}
            subHeaderFontWeight={subHeaderFontWeight}
        >
            <Wrapper orientation={orientation}>
                {children}
                {!hideCta && (
                    <Footer>
                        <Button onClick={confirmAction} variant={ctaVariant}>
                            {closeText}
                        </Button>
                    </Footer>
                )}
            </Wrapper>
        </ModalWrapper>
    );
};
