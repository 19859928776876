import styled, { DefaultTheme } from 'styled-components';
import { Size, Variant } from '../../../lib/ui-types';

const getBorder = (variant: Variant | 'blue', size: Size, theme: DefaultTheme, isTransparent = true) => {
    let color = '';
    const borderSize = size === 'small' ? '2' : size === 'medium' ? '10' : '15';

    switch (variant) {
        case 'dark':
        case 'primary':
            color = !isTransparent ? theme.palette.common.offWhite : 'rgba(250, 250, 250, 0.2)';
            break;
        case 'light':
            color = !isTransparent ? theme.palette.common.black : 'rgb(11, 11, 11, 0.2)';
            break;
        case 'blue':
            color = !isTransparent ? theme.palette.primary.main : 'rgb(0, 68, 94, 0.2)';
            break;
        default:
            color = !isTransparent ? theme.palette.common.offWhite : 'rgba(250, 250, 250, 0.2)';
            break;
    }

    return `${borderSize}px solid ${color}`;
};

const getLoaderSize = (size: Size) => {
    switch (size) {
        case 'small':
            return '1rem';
        case 'medium':
            return '5rem';
        case 'large':
            return '10rem';
        default: {
            console.error('Invalid spinner variant');
            return '1rem';
        }
    }
};

export const StyledLoader = styled.div<{ variant: Variant | 'blue'; size: Size }>`
    &,
    &:after {
        border-radius: 50%;
        width: ${(props) => getLoaderSize(props.size)};
        height: ${(props) => getLoaderSize(props.size)};
    }

    display: inline-block;
    position: relative;
    border-top: ${(props) => getBorder(props.variant, props.size, props.theme)};
    border-right: ${(props) => getBorder(props.variant, props.size, props.theme)};
    border-bottom: ${(props) => getBorder(props.variant, props.size, props.theme)};
    border-left: ${(props) => getBorder(props.variant, props.size, props.theme, false)}; // Color for the moving part

    transform: translateZ(0);

    animation: spinner-animation 1.1s infinite linear;

    @keyframes spinner-animation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
