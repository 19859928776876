import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { ErrorModal } from '../components/shared/modals/error-modal';
import { ErrorType, umbraco } from '../lib/api';
import { handleOverflow } from '../utils/helpers';

type ErrorContext = {
    errorType?: ErrorType;
    pushError: (errorType: ErrorType) => void;
};

export const ErrorHandlingContext = createContext<ErrorContext>({
    pushError: () => {
        return;
    },
});

type Props = {
    errors: umbraco.ErrorInfo[];
};

const ErrorHandlerProvider: FC<Props> = ({ children, errors }) => {
    const [errorType, setErrorType] = useState<ErrorType>();
    const [showModal, setShowModal] = useState(false);

    const errorInfo = useMemo(
        () => errors.find((error) => error.errorType === errorType) ?? errors.find((error) => error.errorType === 'UNKNOWN'),
        [errorType, errors]
    );

    useEffect(() => {
        if (errorType !== undefined) {
            setShowModal(true);
            handleOverflow(true);
        }
    }, [errorType]);

    useEffect(() => {
        if (!showModal) {
            setErrorType(undefined);
        }
    }, [showModal]);

    return (
        <>
            <ErrorHandlingContext.Provider value={{ errorType, pushError: setErrorType }}>{children}</ErrorHandlingContext.Provider>
            <ErrorModal
                isVisible={showModal}
                closeAction={() => {
                    setShowModal(false);
                    handleOverflow(false);
                }}
                description={errorInfo?.description ?? ''}
                headerText={errorInfo?.dialogHeader}
                contactInfo={{
                    email: errorInfo?.contactEmail,
                    emailLabel: errorInfo?.contactEmailLabel,
                    phone: errorInfo?.contactPhoneNumber,
                    phoneLabel: errorInfo?.contactPhoneNumberLabel,
                }}
            />
        </>
    );
};

export default ErrorHandlerProvider;
