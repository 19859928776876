import styled from 'styled-components';
import { IconColor } from '../../../themes/theme';

export const StyledSvg = styled.span<{ iconType: IconColor }>`
    svg {
        height: auto;
        width: auto;

        display: block;

        background-color: ${(props) => props.theme.iconography[props.iconType].background ?? 'unset'};
        padding: ${(props) => (props.theme.iconography[props.iconType].background ? '10px' : 'unset')};
        border-radius: 50%;

        & path {
            stroke: ${(props) => props.theme.iconography[props.iconType].stroke};
        }
    }
`;

export const StyledCustomColorSvg = styled.span<{ customColor: string }>`
    svg {
        height: auto;
        width: auto;
        display: block;
        padding: 0;
        border-radius: 50%;

        & path {
            stroke: ${(props) => props.customColor};
        }
    }
`;
