import { FC } from 'react';
import { ErrorCard, ErrorCardProps } from '../../error-card';
import { SimpleModal } from '../simple-modal';
import { ISimpleModal } from '../simple-modal/simple-modal.props';

type Props = ErrorCardProps & ISimpleModal;

export const ErrorModal: FC<Omit<Props, 'children'>> = ({ headerText, isVisible, closeAction, description, contactInfo, closeText }) => {
    return (
        <SimpleModal headerText={headerText} isVisible={isVisible} closeAction={closeAction} closeText={closeText}>
            <ErrorCard description={description} contactInfo={contactInfo} />
        </SimpleModal>
    );
};
