import { FC } from 'react';
import styled from 'styled-components';
import { mixins } from '../../../themes';

const Container = styled.address`
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 15px;
    row-gap: 5px;
    align-items: center;
`;

const ContactLabel = styled.span`
    grid-column: 1;
    ${mixins.typography('inputLabel')}

    &::first-letter {
        text-transform: uppercase;
    }
`;

const ContactLink = styled.a`
    grid-column: 2;
`;

export type Props = {
    contactInfo: {
        phoneLabel?: string;
        phone?: string;
        emailLabel?: string;
        email?: string;
    };
};

const ContactInfo: FC<Props> = ({ contactInfo: { email, emailLabel, phone, phoneLabel } }) => {
    return (
        <Container>
            {emailLabel && <ContactLabel>{emailLabel}</ContactLabel>}
            {email && <ContactLink href={`mailto:${email}`}>{email}</ContactLink>}
            {phoneLabel && <ContactLabel>{phoneLabel}</ContactLabel>}
            {phone && <ContactLink href={`tel:${phone}`}>{phone}</ContactLink>}
        </Container>
    );
};

export default ContactInfo;
