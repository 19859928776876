import React, { FC } from 'react';
import { SvgIcon } from '../../svg-icon';
import { IModalWrapper } from './wrapper.props';
import { Wrapper, HeadersWrapper, ModalTopSection, Header, SubHeader, ChildrenWrapper, Overlay, ModalDivider } from './wrapper.styled';

export const ModalWrapper: FC<IModalWrapper> = ({
    isVisible = false,
    headerText = '',
    orientation = 'center',
    cancelAction,
    subHeaderText,
    subHeaderFontWeight,
    children,
    removeOverflow = false,
}) => {
    const onOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            cancelAction();
        }
    };
    return isVisible ? (
        <Overlay orientation={orientation} onClick={(clickEvent) => onOverlayClick(clickEvent)}>
            <Wrapper orientation={orientation}>
                <ModalTopSection>
                    <HeadersWrapper>
                        <Header>{headerText}</Header>
                        {subHeaderText && (
                            <SubHeader fontWeight={subHeaderFontWeight}>
                                <small>{subHeaderText}</small>
                            </SubHeader>
                        )}
                    </HeadersWrapper>
                    <span onClick={cancelAction} style={{ cursor: 'pointer' }}>
                        <SvgIcon iconName="close" />
                    </span>
                </ModalTopSection>
                <ModalDivider />
                <ChildrenWrapper removeOverflow={removeOverflow}>{children}</ChildrenWrapper>
            </Wrapper>
        </Overlay>
    ) : null;
};
